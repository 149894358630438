<template>
    <div>
        <div class="error-title">{{ errorMessage }}</div>
        <pre v-if="errorDetails" class="error-details">{{ errorDetails }}</pre>
    </div>
</template>
<script>
export default {
    props:{
        errorMessage:{
            required:true,
            type:String
        },
        errorDetails:{
            required:false,
        }
    },
    methods: {
    },
};

</script>

<style scoped>
.error-title {
  font-size: .9rem;
  font-weight: 500;
  /* color: #ff5252; Vuetify error color */
}

.error-details {
  font-size: 0.9rem;
  color: #757575; /* Vuetify secondary text color */
  margin-top: 4px;
}
</style>