<template>
  <div>
    <router-view></router-view>


    <v-snackbar 
    :timeout="4000"

    :color="snackbar.bg" v-model="snackStatus" :multi-line="true">
      {{ snackMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackbar.color"
          text
          @click="
            setSnackMessage({
              snackbar: {
                status: false,
                color: 'red',
              },
              message: '',
            })
          "
          v-bind="attrs"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


    <v-dialog width="unset" v-model="dialog">
      <delete-dialog
        :what="deleteDialog.what"
        :callback="deleteDialog.callback"
        :data="deleteDialog.data"
        :description="deleteDialog.description"
        :successHandler="deleteDialog.successHandler"
      />
    </v-dialog>
    <v-dialog width="unset" v-model="consoleDialogModel">
      <console-component 
        :messages="consoleComponenent.messages" 
      />
    
    </v-dialog>

    <ErrorDialog/>
    
  </div>  
</template>

<script>
import DeleteDialog from '@/shared/components/DeleteDialog.vue'
import ConsoleComponent from '@/shared/components/ConsoleComponent.vue'
import ErrorDialog from '@/shared/errors/error-bag/entry/ErrorDialog.vue';
import { mapGetters,mapActions } from "vuex";

export default {
  name: 'App',
  components: {
    DeleteDialog,
    ConsoleComponent,
    ErrorDialog
  },
  data() {
    return {
      snackStatus: false,
      dialog: false,
      consoleDialogModel: false,
   
    };
  },
  computed: {
    ...mapGetters(['snackMessage', 'snackbar', 'deleteDialog',"consoleComponenent"]),

  },

  methods: {
    ...mapActions(['setSnackMessage']),
   
  },
  watch: {
    snackbar() {
      this.snackStatus = this.snackbar.status
    },
    deleteDialog() {
      this.dialog = this.deleteDialog.dialog
    },
    consoleComponenent() {
      this.consoleDialogModel = this.consoleComponenent.dialog
    },
  },
};
</script>
<style src="./assets/style.css">
</style>