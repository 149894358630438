<template>
    <div>
        <div class="error-title">{{ errorMessage }}</div>
        <div class="error-details mb-2">
            <p class="mb-0" v-if="errorDetails">
                An error occurred while generating the <strong>{{ errorDetails.modelName }}</strong>
                entity service pack in <strong>{{ errorDetails.entityServicePack }}</strong>.
                .</p>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        errorMessage: {
            required: true,
            type: String
        },
        errorDetails: {
            type: Object,
            required: false,
        }
    },
    methods: {
    },
};

</script>

<style scoped>
.error-title {
    font-size: .9rem;
    font-weight: 500;
    /* color: #ff5252; Vuetify error color */
}

.error-details {
    font-size: 0.9rem;
    color: #616161;
    line-height: 1.5;
    font-style: italic;
}

.error-details strong {
    color: #424242;
    /* Darker text for emphasis */
}
</style>