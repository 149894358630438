module.exports = {
    GENERIC_ERROR: "ERR001",
    VALIDATION_ERROR: "ERR002",
    ACCOUNT_ACTIVE: "ERR003",
    ACCOUNT_DISABLED: "ERR004",
    ACCOUNT_UNPUBLISHED: "ERR005",
    ACCOUNT_BLOCKED: "ERR006",
    EMAIL_VERIFICATION_CODE_EXPIRED: "ERR007",
    PASSWORD_RESTORATION_CODE_EXPIRED: "ERR008",
    INVALID_EMAIL_VERIFICATION_CODE: "ERR009",
    INVALID_PASSWORD_VERIFICATION_CODE: "ERR010",
    ACCESS_DENIED: "ERR011",
    REQUIRE_LOGIN: "ERR012",
    INVALID_LOGIN: "ERR013",
    EXPIRED: "ERR014",
    CAN_NOT_FIND: "ERR015",
    ALREADY_EXIST: "ERR016",
    ALREADY_CLOSE: "ERR017",
    IN_PROGRESS: "ERR018",
    BALANCE: "ERR019",
    IS_NAN: "ERR020",
    IS_NOT_VALID: "ERR021",
    IS_NOT_VERIFY: "ERR022",
    IS_REQUIRED: "ERR023",
    NOT_BELONGS_TO_YOU: "ERR024",
    PASSWORD_NOT_MATCH: "ERR025",
    INSUFFICIENT_BALANCE: "ERR026",
    UNDEFINED_TOKEN: "ERR027",
    INVALID_TOKEN: "ERR028",
    INVALID_ROLE: "ERR029",
    PROJECT_MODULES_MAX_ALLOWED_SIZED_REACHED: "ERR030",
    FULL_PROJECT_MAX_ALLOWED_SIZED_REACHED: "ERR030",
    CODE_GENERATION_MISSING_SHARED_SERVICE: "ERR031",
    ERRORS_BAG: "ERR032",
};