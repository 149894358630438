var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('router-view'),_c('v-snackbar',{attrs:{"timeout":4000,"color":_vm.snackbar.bg,"multi-line":true},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":_vm.snackbar.color,"text":""},on:{"click":function($event){return _vm.setSnackMessage({
            snackbar: {
              status: false,
              color: 'red',
            },
            message: '',
          })}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackStatus),callback:function ($$v) {_vm.snackStatus=$$v},expression:"snackStatus"}},[_vm._v(" "+_vm._s(_vm.snackMessage)+" ")]),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('delete-dialog',{attrs:{"what":_vm.deleteDialog.what,"callback":_vm.deleteDialog.callback,"data":_vm.deleteDialog.data,"description":_vm.deleteDialog.description,"successHandler":_vm.deleteDialog.successHandler}})],1),_c('v-dialog',{attrs:{"width":"unset"},model:{value:(_vm.consoleDialogModel),callback:function ($$v) {_vm.consoleDialogModel=$$v},expression:"consoleDialogModel"}},[_c('console-component',{attrs:{"messages":_vm.consoleComponenent.messages}})],1),_c('ErrorDialog')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }