<template>
    <v-dialog v-model="isVisible" max-width="70rem" persistent>
        <v-card>
            <v-card-title class="headline error--text">
                <v-icon color="error" class="mr-2">mdi-alert-circle</v-icon>
                Error Occurred
            </v-card-title>

            <v-card-text>
                <div v-for="(error, index) in errors" :key="index" class="mb-4">
                    <div v-if="error.errorCode">
                        <CodeGenerationMissingSharedService :errorMessage="error.message"
                            :errorDetails="error.details" />
                    </div>
                    <div v-else>
                        <GenericError :errorMessage="error.message" :errorDetails="error.details" />
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">Close</v-btn>
                <!-- <v-btn color="secondary" text @click="retry">Retry</v-btn> -->
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import GenericError from '../components/GenericError.vue';
import CodeGenerationMissingSharedService from '../components/CodeGenerationMissingSharedService.vue';

import errorCodes from '@/shared/constants/errorCodes'
export default {
    components: {
        GenericError,
        CodeGenerationMissingSharedService
    },
    // props: {
    //     propsErrors: {
    //         default: () => ([]),
    //         type: Array
    //     }
    // },
    data() {
        return {
            isVisible: false,
            errors: [],
            errorCodes
        };
    },
    created() {
        // Listen for the global event to show the dialog
        this.$eventBus.$on('show-error-dialog', this.showDialog);
    },
    beforeDestroy() {
        // Clean up the event listener
        this.$eventBus.$off('show-error-dialog', this.showDialog);
    },
    methods: {
        showDialog(errors) {
            this.errors = errors;
            this.isVisible = true;
        },
        closeDialog() {
            this.isVisible = false;
            this.errors = [];
        },
    },
};
</script>
