import axios from "axios";
const projectService = (routeName) => `/js-modular-monolith-express-service${routeName}`;
const mutations = {


    SET_PROJECT_APP_BACKEND_MODULE(state, data) {
        state.projectAppBackendModule = data;
    },

    SET_APP_MIDDLEWARE_METHODS(state, data) {
        state.appMiddlewareMethods = data;
    },
    /**
     * @appBackendModule
     */
    SET_PROJECT_APP_BACKEND_MODULE_SERVICES(state, data) {
        state.projectAppBackendModuleServices = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE_CONTROLLERS(state, data) {
        state.projectAppBackendModuleControllers = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE_VALIDATIONS(state, data) {
        state.projectAppBackendModuleValidations = data;
    },
    SET_PROJECT_APP_BACKEND_MODULE_ROUTES(state, data) {
        state.projectAppBackendModuleRoutes = data;
    },
    /**
     * @model
     */
    SET_MODULE_MODELS(state, data) {
        state.moduleModels = data;
    },
    SET_APP_MODULE_MODELS_ENTITY_SERVICE_PACKS(state, data) {
        state.appModuleModelsEntityServicePacks = data;
    },
    SET_APP_MODULE_MODEL_ENTITY_SERVICE_PACKS(state, data) {
        state.appModuleModelEntityServicePacks = data;
    },
    SET_APP_MODULE_MODELS_RESOURCES(state, data) {
        state.appModuleModelsResources = data;
    },
    SET_MODEL(state, data) {
        state.model = data;
    },
    /**
     * @attribute
     */
    SET_ATTRIBUTES(state, data) {
        state.attributes = data;
    },
    SET_ATTRIBUTE(state, data) {
        state.attribute = data;
    },

}

const state = () => ({


    projectAppBackendModule: {},
    projectAppBackendModuleServices: [],
    projectAppBackendModuleControllers: [],
    projectAppBackendModuleRoutes: [],
    projectAppBackendModuleValidations: [],
    model: {},
    moduleModels: [],
    appModuleModelEntityServicePacks: [],
    appModuleModelsEntityServicePacks: [],
    appModuleModelsResources: [],
    appMiddlewareMethods: [],
})

const getters = {


    projectAppBackendModule: state => state.projectAppBackendModule,
    projectAppBackendModuleServices: state => state.projectAppBackendModuleServices,
    projectAppBackendModuleControllers: state => state.projectAppBackendModuleControllers,
    projectAppBackendModuleValidations: state => state.projectAppBackendModuleValidations,
    projectAppBackendModuleRoutes: state => state.projectAppBackendModuleRoutes,
    model: state => state.model,
    moduleModels: state => state.moduleModels,
    appModuleModelEntityServicePacks: state => state.appModuleModelEntityServicePacks,
    appModuleModelsEntityServicePacks: state => state.appModuleModelsEntityServicePacks,
    appModuleModelsResources: state => state.appModuleModelsResources,
    appMiddlewareMethods: state => state.appMiddlewareMethods,
}


const actions = {

    getProjectAppBackendModule({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}`))
                .then(res => {
                    commit("SET_PROJECT_APP_BACKEND_MODULE", res.data.data);
                    resolve(res.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @model
     */
    getAppModuleModels({ commit }, { project, isActor = '' }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/app/${project}/model?isActor=${isActor}`))
                .then(res => {
                    commit("SET_MODULE_MODELS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getAppModuleModelsEntityServicePacks({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}/models/entity-service-pack`))
                .then(res => {
                    commit("SET_APP_MODULE_MODELS_ENTITY_SERVICE_PACKS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getAppModuleModelEntityServicePacks({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodulemodel/${id}/entity-service-pack`))
                .then(res => {
                    commit("SET_APP_MODULE_MODEL_ENTITY_SERVICE_PACKS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getModuleModelsResources({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}/models/resources`))
                .then(res => {
                    commit("SET_APP_MODULE_MODELS_RESOURCES", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    /**
     * @model
     */
    getAppMiddlewareMethods({ commit }, { project, isFromSharedFolder = '' }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/app/${project}/middleware-method?isFromSharedFolder=${isFromSharedFolder}`))
                .then(res => {
                    commit("SET_APP_MIDDLEWARE_METHODS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProjectAppBackendModuleServices({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}/service`))
                .then(res => {
                    commit("SET_PROJECT_APP_BACKEND_MODULE_SERVICES", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProjectAppBackendModuleControllers({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}/controller`))
                .then(res => {
                    commit("SET_PROJECT_APP_BACKEND_MODULE_CONTROLLERS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProjectAppBackendModuleValidations({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}/validation`))
                .then(res => {
                    commit("SET_PROJECT_APP_BACKEND_MODULE_VALIDATIONS", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    getProjectAppBackendModuleRoutes({ commit }, { id }) {
        return new Promise((resolve, reject) => {
            axios
                .get(projectService(`/backendmodule/${id}/route`))
                .then(res => {
                    commit("SET_PROJECT_APP_BACKEND_MODULE_ROUTES", res.data.data);
                    resolve(res.data.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

}


export default {
    state,
    actions,
    mutations,
    getters,
}