import Vue from 'vue';

export function showErrorsBag(errors) {
    if (Array.isArray(errors) && errors.length > 0) {
        // Emit a global event to show the error dialog
        Vue.prototype.$eventBus.$emit('show-error-dialog', errors);
    }
}
// export function showError(error) {
//     // Emit a global event to show the error dialog
//     Vue.prototype.$eventBus.$emit('show-error-dialog', [error]);
// }